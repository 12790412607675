const InnerLayout = () =>
  import(/* webpackChunkName:'innerLayout' */ "@/layouts/inner-layout");
const Option = () =>
  import(/* webpackChunkName:'baseTable' */ "@/pages/incentive/option");
const Stock = () =>
  import(/* webpackChunkName:'complexTable' */ "@/pages/incentive/stock");

const route = {
  name: "Incentive",
  path: "/incentive",
  component: InnerLayout,
  meta: {
    title: "激励计划",
    icon: "icon-jilijihua",
  },
  children: [
    {
      name: "IncentiveOption",
      path: "/incentive/option",
      component: Option,
      meta: {
        title: "期权计划",
        type: "option",
      },
    },
    {
      name: "IncentiveStock",
      path: "/incentive/stock",
      component: Stock,
      meta: {
        title: "股票计划",
        type: "stock",
      },
    },
  ],
};

export default route;
