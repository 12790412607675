const InnerLayout = () =>
  import(/* webpackChunkName:'innerLayout' */ "@/layouts/inner-layout");
const Platform = () =>
  import(/* webpackChunkName:'Platform' */ "@/pages/platform");

const route = {
  path: "/platform",
  component: InnerLayout,
  redirect: "/platform/index",
  meta: {
    title: "持股平台",
    icon: "icon-chigupingtai",
  },
  children: [
    {
      name: "Platform",
      path: "/platform/index",
      component: Platform,
      meta: {
        title: "持股平台",
        activePath: "/platform",
      },
    },
  ],
};

export default route;
