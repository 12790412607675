const InnerLayout = () =>
  import(/* webpackChunkName:'innerLayout' */ "@/layouts/inner-layout");
const Condition = () =>
  import(/* webpackChunkName:'Condition' */ "@/pages/condition");

const route = {
  path: "/condition",
  component: InnerLayout,
  redirect: "/condition/index",
  meta: {
    title: "成熟/归属条件",
    icon: "icon-guishutiaojian",
  },
  children: [
    {
      name: "Condition",
      path: "/condition/index",
      component: Condition,
      meta: {
        title: "成熟/归属条件",
        activePath: "/condition",
      },
    },
  ],
};

export default route;
