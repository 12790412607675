<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import api from "@/api";
import tableMng from "@/utils/tableMng";
export default {
  components: {},
  created() {
    var userAgent = navigator.userAgent; // 获取浏览器信息
    var IEReg = new RegExp("MSIE \\d+\\.\\d+;"); // 正则校验IE标识符
    var IEMsg = IEReg.exec(userAgent); // 获取字符串
    var IEVersionNum = new RegExp("\\d+\\.\\d"); // 正则获取版本

    // 是IE9，引入js文件
    if (IEMsg && parseFloat(IEVersionNum.exec(IEMsg)) == 9) {
      import("@/utils/flex.native.min.js");
    }
    this.initBaseTable();
  },
  methods: {
    //  初始化基础数据表
    async initBaseTable() {
      const data = await api.common.publicConf();
      tableMng.initTable({ ...data });
    },
  },
};
</script>
<style lang="scss">
#app {
  font-size: 14px;
  color: #333333;
}
</style>
