<template>
  <div class="text-overflow">
    <el-tooltip
      popper-class="text-overflow__tooltip"
      effect="light"
      :disabled="tooltipDisabled"
    >
      <template v-if="isArrayFn(text)">
        <div slot="content" v-for="(item, idx) in text" :key="idx">
          {{ item + ";" }}<br />
        </div>
      </template>
      <span v-else slot="content">
        {{ text }}
      </span>

      <div v-if="limit > 0">
        {{ content }}
      </div>

      <div
        :class="[ellipsisClass, 'lineLimitClass']"
        :style="ellipsisStyle"
        ref="lineLimit"
        v-else
      >
        {{ text }}
      </div>
    </el-tooltip>
  </div>
</template>

<script>
import { isArrayFn } from "@/utils/core";
export default {
  props: {
    // 文本内容
    text: {
      type: [String, Object, Array],
      default: "",
    },
    // 文字个数限制
    limit: {
      type: Number,
      default: 0,
    },
    // 内容宽度限制
    width: {
      type: Number,
      default: 0,
    },
    // 内容行数限制
    lineClamp: {
      type: Number,
      default: 0,
    },
    // 是否显示tooltip
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isArrayFn,
    };
  },
  computed: {
    content() {
      const { text, limit } = this;
      // console.log(text)
      if (typeof text === "object" && isArrayFn(text)) {
        return text[0];
      } else if (text.length <= limit) {
        return text;
      } else {
        return text.slice(0, limit) + "...";
      }
    },
    ellipsisClass() {
      return this.lineClamp > 0
        ? "text-overflow__multiple"
        : "text-overflow__single";
    },
    ellipsisStyle() {
      return {
        width: this.width > 0 ? this.width + "px" : "100%",
        "-webkit-line-clamp": this.lineClamp,
      };
    },
    tooltipDisabled() {
      const textLengthOver = this.text.length < this.limit;
      return textLengthOver;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-overflow {
  //多行文本文字超过行数限制后显示省略号
  .lineLimitClass {
    max-width: 400px;
  }
  &__multiple {
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -moz-box;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
  }

  // 单行文本超出显示省略号
  &__single {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
<style>
.text-ellipsis__tooltip {
  max-width: 250px;
}
.text-overflow__tooltip {
  max-width: 500px;
}
</style>
