<template>
  <div class="text-overflow">
    <el-tooltip
      popper-class="text-overflow__tooltip"
      effect="light"
      :content="text"
      :disabled="tooltipDisabled"
    >
      <div v-if="limit > 0">
        {{ content }}
      </div>

      <div
        :class="[ellipsisClass, 'lineLimitClass']"
        :style="ellipsisStyle"
        ref="lineLimit"
        v-else
      >
        {{ text }}
      </div>
    </el-tooltip>
  </div>
</template>

<script>
import { isArrayFn } from "@/utils/core";
export default {
  props: {
    // 文本内容
    text: {
      type: [String, Object, Array],
      default: "",
    },
    // 文字个数限制
    limit: {
      type: Number,
      default: 0,
    },
    // 内容宽度限制
    width: {
      type: Number,
      default: 0,
    },
    // 是否显示tooltip
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isArrayFn,
    };
  },
  computed: {
    content() {
      const { text, limit, stringSplit } = this;
      const result = stringSplit(text, limit);
      return result;
    },
    ellipsisClass() {
      return this.lineClamp > 0
        ? "text-overflow__multiple"
        : "text-overflow__single";
    },
    ellipsisStyle() {
      return {
        width: this.width > 0 ? this.width + "px" : "100%",
        "-webkit-line-clamp": this.lineClamp,
      };
    },
    tooltipDisabled() {
      const textLengthOver =
        this.text.replace(/[\u4e00-\u9fa5]/g, "aa").length < this.limit;
      return textLengthOver;
    },
  },
  methods: {
    stringSplit(text, length) {
      if (text.replace(/[\u4e00-\u9fa5]/g, "aa").length <= length) {
        return text;
      } else {
        var _length = 0;
        var outputText = "";
        for (var i = 0; i < text.length; i++) {
          if (/[\u4e00-\u9fa5]/.test(text[i])) {
            _length += 2;
          } else {
            _length += 1;
          }
          if (_length > length) {
            break;
          } else {
            outputText += text[i];
          }
        }
        return outputText + "...";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-overflow {
  //多行文本文字超过行数限制后显示省略号
  .lineLimitClass {
    max-width: 400px;
  }
  &__multiple {
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -moz-box;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
  }

  // 单行文本超出显示省略号
  &__single {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
<style>
.text-ellipsis__tooltip {
  max-width: 250px;
}
.text-overflow__tooltip {
  max-width: 500px;
}
</style>
