import Vue from "vue";
import VueRouter from "vue-router";
// import store from "@/store";
// import service from "@/service/service"
Vue.use(VueRouter);

import accountRoute from "./modules/account";
import dashboardRoute from "./modules/dashboard";
import errorRoute from "./modules/error";
import reloadRoute from "./modules/reload";
import participants from "./modules/participants"; //参与人
import incentive from "./modules/incentive"; //激励计划
import awarded from "./modules/awarded"; //授予管理
import platform from "./modules/platform"; //持股平台
import condition from "./modules/condition"; //成熟条件
import otherPage from "./modules/other"; //其他页面

const staticRouteMap = [
  {
    path: "/",
    redirect: "/dashboard",
    meta: {
      hiddenInMenu: true,
    },
  },
  accountRoute,
  dashboardRoute,
  reloadRoute,
  errorRoute,
  participants,
  platform,
  incentive,
  awarded,
  condition,
  ...otherPage,
  {
    name: "404",
    path: "*",
    redirect: "/error/notFound",
    meta: {
      hiddenInMenu: true,
    },
  },
];

const createRouter = () =>
  new VueRouter({
    routes: staticRouteMap,
    scrollBehavior(to, from, savedPosition) {
      // 本项目布局使用的是.inner-layout__page滚动，scrollBehavior返回的位置是指window的滚动，所有滚动不会生效。
      const innerPage = document.querySelector(".inner-layout__page");
      if (innerPage) {
        innerPage.scrollTo(0, 0);
      }
      return { x: 0, y: 0 };
    },
  });

// 退出登录的时候执行，防止重复注册路由
const resetRouter = () => {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
};

const router = createRouter();

// 导航守卫
router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("token");
  // console.log(token);
  const outerPaths = ["/account/login", "/applyUse"];
  if (!token && !outerPaths.includes(to.path)) {
    next("/account/login");
  } else {
    next();
  }
});

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export { resetRouter };

export default router;
