import service from "@/service/service";

//期权计划列表
export const optionPlanList = (data) => service.get("/option/plan/list", data);
//删除期权计划
export const optionPlanDel = (data) => service.post("/option/plan/del", data);

//新增期权计划
export const optionPlanAdd = (data) => service.post("/option/plan/add", data);
//修改期权计划
export const optionPlanEdit = (data) => service.post("/option/plan/edit", data);

// 持股平台
export const platformList = (data) => service.get("/platform/list", data);

//期权计划列表
export const stockPlanList = (data) => service.get("/stock/plan/list", data);
//删除期权计划
export const stockPlanDel = (data) => service.post("/stock/plan/del", data);

//新增期权计划
export const stockPlanAdd = (data) => service.post("/stock/plan/add", data);
//修改期权计划
export const stockPlanEdit = (data) => service.post("/stock/plan/edit", data);
// 平台统计
export const statPlan = (data) => service.get("/stat/plan", data);
