import Vue from "vue";
import Vuex from "vuex";

import account from "./modules/account";
import cache from "./modules/cache";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account,
    cache,
  },
  state: {
    userInfo: {},
    token: "",
  },

  getters: {
    token: (state) => state.account.token,
    userInfo: (state) => state.account.userInfo,
    routeMap: (state) => state.account.routeMap,
    cachePages: (state) => state.cache.cachePages,
    sideCollapse: (state) => state.cache.sideCollapse,
  },
  mutations: {},
  actions: {},
});
