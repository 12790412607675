import service from "@/service/service";

//持股平台列表
export const platformList = (data) => service.get("/platform/list", data);

//新增持股平台
export const platformAdd = (data) => service.post("/platform/add", data);

//修改持股平台
export const platformEdit = (data) => service.post("/platform/edit", data);

//删除持股平台
export const platformDel = (data) => service.post("/platform/del", data);

//持股平台统计
export const platformDataV = (data) => service.get("/stat/platform?platform_id="+data);
