// import "babel-polyfill";
import "core-js";
import Es6Promise from "es6-promise";
Es6Promise.polyfill();
import Vue from "vue";
import dayjs from "dayjs";

import ElementUI from "element-ui";

import "@/assets/styles/element-variables.scss";
import App from "@/App";
import router from "@/router";
import store from "@/store";
// import filters from "@/filters";
// import "../theme/index.css";

// import cssVars from "css-vars-ponyfill";
// cssVars({});

// import service from "@/service/service";
// import api from "@/api";
import { addAllSelect } from "@/utils/core";

import "default-passive-events";
// rem自适应
// import "@/utils/amfe-flexible";

import "@/assets/styles/app.scss";
// 全局注册的组件、指令、过滤器
import "@/components";
import "@/directive";
import "@/filters";

import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

Vue.use(ElementUI);

import AFTableColumn from "@/components/base/af-table-column/af-table-column.common.js";
// 注册组件
Vue.use(AFTableColumn);

Object.defineProperty(Vue.prototype, "$addAllSelect", {
  value: addAllSelect,
});

Object.defineProperty(Vue.prototype, "$dayjs", {
  value: dayjs,
});

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router: router,
  store: store,
  render: (h) => h(App),
});
