import service from "@/service/service";
import axios from "axios";
import config from "@/config";
// 登陆
export const publicLogin = (data) => service.post("/public/login", data);
// 获取验证码
export const publicCaptcha = () =>
  axios.get(config.apiURL + "/public/captcha", {
    responseType: "arraybuffer",
    timeout: 20000,
  });
//   退出
export const publicLogout = (data) => service.get("/public/logout", data);

// 修改密码
export const publicPwdEdit = (data) => service.post("/public/pwd/edit", data);
