/* 应用配置 */
// 根据环境不同引入不同api地址
import {
  baseApi, //12
  appBaseUrl,
  appQkzqBaseUrl,
} from "@/configs";
const config = {
  apiURL: baseApi,
  appBaseUrl,
  appQkzqBaseUrl,
  // 是否折叠侧边菜单
  sideCollapse: false,
};

export default config;
