import Vue from "vue";

import formatDate from "./formatDate";
import { number_format } from "@/utils/core";

const filters = {
  formatDate,
  number_format,
};

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});
