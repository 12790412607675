const InnerLayout = () =>
  import(/* webpackChunkName:'innerLayout' */ "@/layouts/inner-layout");
const NotFound = () =>
  import(/* webpackChunkName:'notFound' */ "@/pages/error/not-found");

const route = {
  name: "Error",
  path: "/error",
  component: InnerLayout,
  redirect: "/error/notFound",
  meta: {
    title: "错误处理",
    icon: "icon-kaihuguanli",
    hiddenInMenu: true,
  },
  children: [
    {
      name: "NotFound",
      path: "/error/notFound",
      component: NotFound,
      meta: {
        title: "404",
        noCache: true,
        activePath: "/error",
      },
    },
  ],
};

export default route;
