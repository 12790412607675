const ApplyUse = () =>
  import(/* webpackChunkName:'ApplyUse' */ "@/layouts/other-page/apply-use");

const route = [
  {
    path: "/applyUse",
    name: "ApplyUse",
    component: ApplyUse,
    meta: {
      title: "试用申请",
      hiddenInMenu: true,
    },
  },
];

export default route;
