// 注册全局组件，这样使用的时候不用每次都import。
import Vue from "vue";
import SvgIcon from "./base/svg-icon";
import TextWrapped from "./base/text-wrapped";
import TextOverflow from "./base/text-overflow";
import ChacterOverflow from "./base/chacter-overflow";
import TipText from "./business/tip-text";
Vue.component("svg-icon", SvgIcon);
Vue.component("tip-text", TipText);
Vue.component("text-wrapped", TextWrapped);
Vue.component("text-overflow", TextOverflow);
Vue.component("chacter-overflow", ChacterOverflow);

//导入所有svg图标，然后结合svg-sprite-loader可以按需使用
const requireContext = require.context(
  "./base/svg-icon/icons",
  false,
  /\.svg$/
);
const requireAll = (requireContext) =>
  requireContext.keys().map(requireContext);
requireAll(requireContext);
