<template>
  <svg-icon
    name="tip"
    class="tip-text"
    :size="size"
    @click.native="confirmTip"
  />
</template>

<script>
export default {
  props: {
    tipText: {
      type: String,
      default: "",
    },
    size: {
      type: [String, Number],
      default: 14,
    },
  },
  methods: {
    confirmTip() {
      // console.log(1);
      if (!this.tipText) return;
      this.$alert(
        this.tipText,
        // "<div>统计中的“授予总数”和“成熟总数”与授予记录<br/>中的“授予股数”和“已成熟数”不是简单的汇<br />总关系。</div><div>考虑了“已终止”状态的授予记录中“已回收数”<br />对统计中“授予总数”和“成熟总数”的影响。</div>",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.tip-text {
  cursor: pointer;
}
</style>
