import * as common from "./modules/common";
import * as account from "./modules/account";
import * as dashboard from "./modules/dashboard";
import * as platform from "./modules/platform";
import * as participants from "./modules/participants";
import * as incentive from "./modules/incentive";
import * as awarded from "./modules/awarded";
import * as condition from "./modules/condition";
import * as other from "./modules/other";
import * as oss from "./modules/oss";
const api = {
  common,
  dashboard,
  platform,
  account,
  participants,
  incentive,
  awarded,
  condition,
  other,
  oss,
};

export default api;
