// 正式
let windowObj = {};
if (typeof window !== "undefined") windowObj = window;
const baseUrl = windowObj?.location?.origin || "https://esop.qkintl.com";

const appBaseUrl = "https://m.esop.qksec.hk";
const appQkzqBaseUrl = "https://app.qksec.hk";
module.exports = {
  baseApi: `${baseUrl}/api`, // 测试api请求地址
  appBaseUrl: `${appBaseUrl}/api`,
  appQkzqBaseUrl: `${appQkzqBaseUrl}/api`,
};
