import service1 from "@/service/service1";
import service2 from "@/service/service2";
// esop试用申请
export const esopTrialApply = (data) =>
  service1.post("/esop/trial/apply", data);
// 获取验证码
export const phonecodeGet = (data) => service2.get("/phonecode/get", data);

// 极验验证码
export const securityGet = (data) => service2.get("/security/get", data);
export const securityValid = (data) => service2.post("/security/valid", data);
