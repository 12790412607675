import service from "@/service/service";

//参与人列表
export const staffList = (data) => service.get("/staff/list", data);

//新增参与人
export const staffAdd = (data) => service.post("/staff/add", data);

//修改参与人
export const staffEdit = (data) => service.post("/staff/edit", data);

//删除参与人
export const staffDel = (data) => service.post("/staff/del", data);

//导入参与人
export const staffImport = (data) =>
  service.postOnly("/staff/import", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

// 平台统计
export const statUser = (data) => service.get("/stat/user", data);
