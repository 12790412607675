import service from "@/service/service";


//成熟条件列表
export const conditionList = (data) => service.get("/condition/list", data);

//新增成熟条件
export const conditionAdd = (data) => service.post("/condition/add", data);

//修改成熟条件
export const conditionEdit = (data) => service.post("/condition/edit", data);

//删除成熟条件
export const conditionDel = (data) => service.post("/condition/del", data);

//检查引用状态
export const getConditionCheckStatus = (data) => service.get("/condition/check/ref", data);

//成熟条件统计
// export const platformDataV = (data) => service.get("/stat/platform?platform_id="+data);

