/* 基础数据表管理 */
/**
 *判断是否是数字
 *
 **/

function isRealNum(val) {
  // isNaN()函数 把空串 空格 以及NUll 按照0来处理 所以先去除，
  if (val === "" || val == null) {
    return false;
  }
  if (!isNaN(val)) {
    return true;
  } else {
    return false;
  }
}
// 表名。方便使用的时候查看
const TABLE_NAME_MAP = {};

class TableMng {
  baseTable = {};

  constructor(data) {
    this.baseTable = data;
    this.tableNameMap = TABLE_NAME_MAP;
  }

  // 初始化数据表
  initTable(data) {
    const baseTable = {
      ...this.baseTable,
      ...data,
    };
    this.baseTable = baseTable;
    sessionStorage.setItem("baseTable", window.JSON.stringify(baseTable));
  }

  /**
   * 获取某个表
   * @param {String} tableName 表名
   */
  getTable(tableName) {
    const table = this.baseTable[tableName];
    if (table) {
      const arr = [];
      Object.keys(table).map((item) => {
        arr.push({
          id: item,
          name: table[item],
        });
      });
      return arr;
    } else {
      throw new Error(`表“${tableName}”不存在`);
    }
  }
  getList(tableName) {
    const table = this.baseTable[tableName];
    if (table) {
      return table;
    } else {
      throw new Error(`表“${tableName}”不存在`);
    }
  }
  /**
   * 获取某个表的所有项的id
   * @param {String} tableName 表名
   */
  getIds(tableName) {
    const table = this.getTable(tableName);
    return table.map((item) => item.id);
  }

  /**
   * 获取某个表的所有项的name
   * @param {String} tableName 表名
   */
  getNames(tableName) {
    const table = this.getTable(tableName);
    return table.map((item) => item.name);
  }

  /**
   * 获取某个表中某一项的名称
   * @param {String} tableName 表名
   * @param {String} id  ID
   *
   */
  getNameById(tableName, id) {
    if (id === null || id === undefined) {
      return "---";
    }
    const table = this.getTable(tableName);
    const result = table.find((item) => item.id.toString() === id.toString());
    return result ? result.name : id;
  }

  // 格式化为前端需要的数据结构
  formatTable(tableName, idFiled, nameFiled) {
    const table = this.getTable(tableName);
    return table.map((item) => ({
      [idFiled]: item.id,
      [nameFiled]: item.name,
    }));
  }
}

//如果后端未提供，前端也可以自行定义
const fixTable = {};

const storageTable = JSON.parse(
  sessionStorage.getItem("baseTable") || window.JSON.stringify({})
);

const tableMng = new TableMng({
  ...fixTable,
  ...storageTable,
});

export default tableMng;
