const InnerLayout = () =>
  import(/* webpackChunkName:'innerLayout' */ "@/layouts/inner-layout");
const Participants = () =>
  import(/* webpackChunkName:'participants' */ "@/pages/participants");

const route = {
  path: "/participants",
  component: InnerLayout,
  redirect: "/participants/index",
  meta: {
    title: "参与人",
    icon: "icon-canyuren",
  },
  children: [
    {
      name: "Participants",
      path: "/participants/index",
      component: Participants,
      meta: {
        title: "参与人",
        activePath: "/participants",
      },
    },
  ],
};

export default route;
