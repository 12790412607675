let windowObj = {};
if (typeof window !== "undefined") windowObj = window;
// console.log(windowObj);
const baseUrl = windowObj?.location?.origin || "http://192.168.230.251:8130";
const appBaseUrl = "http://192.168.230.251:8121";
const appQkzqBaseUrl = "http://192.168.230.251:8021";
module.exports = {
  baseApi: `${baseUrl}/api`, // 测试api请求地址
  appBaseUrl: `${appBaseUrl}/api`,
  appQkzqBaseUrl: `${appQkzqBaseUrl}/api`,
};
