import service from "@/service/service";

//期权计划列表
export const optionAwardList = (data) =>
  service.get("/option/award/list", data);
//删除期权计划
export const optionAwardDel = (data) => service.post("/option/award/del", data);

//新增期权计划
export const optionAwardAdd = (data) => service.post("/option/award/add", data);
//修改期权计划
export const optionAwardEdit = (data) =>
  service.post("/option/award/edit", data);
// 授予详情-期权行权列表
export const optionExerciseList = (data) =>
  service.get("/option/exercise/list", data);
// 授予详情-期权行权
export const optionExerciseAdd = (data) =>
  service.post("/option/exercise/add", data);
// 授予详情-期权行权修改
export const optionExerciseEdit = (data) =>
  service.post("/option/exercise/edit", data);
// 成熟详情
export const optionMatureList = (data) =>
  service.get("/option/mature/list", data);

//终止授予
export const optionAwardStop = (data) =>
  service.post("/option/award/stop", data);
// 授予详情-期权确认成熟
export const optionMatureConfirm = (data) =>
  service.post("/option/mature/confirm", data);
// 持股平台
export const platformList = (data) => service.get("/platform/list", data);

//股票计划列表
export const stockAwardList = (data) => service.get("/stock/award/list", data);
//删除股票计划
export const stockAwardDel = (data) => service.post("/stock/award/del", data);

//新增股票计划
export const stockAwardAdd = (data) => service.post("/stock/award/add", data);
//修改股票计划
export const stockAwardEdit = (data) => service.post("/stock/award/edit", data);

//导入期权
export const optionAwardImport = (data) =>
  service.postOnly("/option/award/import", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
//导入股票
export const stockAwardImport = (data) =>
  service.postOnly("/stock/award/import", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

// 授予详情-期权行权列表
export const stockExerciseList = (data) =>
  service.get("/stock/exercise/list", data);
// 授予详情-期权行权
export const stockExerciseAdd = (data) =>
  service.post("/stock/exercise/add", data);
// 授予详情-期权行权修改
export const stockExerciseEdit = (data) =>
  service.post("/stock/exercise/edit", data);
// 成熟详情
export const stockMatureList = (data) =>
  service.get("/stock/mature/list", data);

//终止授予
export const stockAwardStop = (data) => service.post("/stock/award/stop", data);
// 授予详情-期权确认成熟
export const stockMatureConfirm = (data) =>
  service.post("/stock/mature/confirm", data);

// 授予详情-股票归属
export const stockMatureAllot = (data) =>
  service.post("/stock/mature/allot", data);
// 授予详情-修改股票归属
export const stockMatureAllotEdit = (data) =>
  service.post("/stock/mature/allot/edit", data);

// 授予详情-股票回收
export const stockMatureRecycle = (data) =>
  service.post("/stock/mature/recycle", data);
