<template>
  <div class="text-wrapped">
    <div v-for="(item, index) in contentArr" :key="index">
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 文本内容
    text: {
      type: String,
      default: "",
    },
    // 文字个数限制
    limit: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    contentArr() {
      const { text, limit, textSplit } = this;
      const result = textSplit(text, limit);
      return result;
    },
  },
  methods: {
    textSplit(text, length, arr = []) {
      const splitStr = this.stringSplit(text, length);
      arr.push(splitStr);
      const surplusStr = text.slice(splitStr.length, text.length);
      if (!surplusStr) return arr;
      else return this.textSplit(surplusStr, length, arr);
      // if(text.length > 0)
    },
    stringSplit(text, length) {
      if (text.replace(/[\u4e00-\u9fa5]/g, "aa").length <= length) {
        return text;
      } else {
        var _length = 0;
        var outputText = "";
        for (var i = 0; i < text.length; i++) {
          if (/[\u4e00-\u9fa5]/.test(text[i])) {
            _length += 2;
          } else {
            _length += 1;
          }
          if (_length > length) {
            break;
          } else {
            outputText += text[i];
          }
        }
        return outputText;
      }
    },
    // textSplit(str, leng) {
    //   let arr = [];

    //   let index = 0;
    //   while (index < str.length) {
    //     arr.push(str.slice(index, (index += leng)));
    //   }
    //   return arr;
    // },
  },
};
</script>

<style lang="scss" scoped>
.text-wrapped {
}
</style>
