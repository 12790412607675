import config from "@/config";
const state = {
  // 需要缓存的页面
  cachePages: [],
  sideCollapse: localStorage.getItem("sideCollapse")
    ? !!+localStorage.getItem("sideCollapse")
    : config.sideCollapse, // Boolean(Number('1'))
};
const mutations = {
  SET_CACHE_PAGES(state, pages) {
    //开发环境不启用,防止热更新失效
    if (process.env.NODE_ENV === "development") {
      state.cachePages = [];
    } else {
      state.cachePages = pages;
    }
  },
  SET_SIDE_COLLAPSE(state, collapse) {
    state.sideCollapse = collapse;
    localStorage.setItem("sideCollapse", collapse ? "1" : "0");
  },
};
export default {
  namespaced: true,
  state,
  mutations,
};
