// // DOM级权限控制，若当前的角色不在指令传入的权限数组中，则该DOM元素不渲染。

// import { number_format } from "@/utils/core";
// /**
//  * @example
//  * v-number-format="{ decimals:2,dec_point:'.',thousands_sep:"," }" 只允许输入一位小数
//  * @param limit 小数位数
//  */
// export default {
//   bind: (el, bind) => {
//     const input =
//       el.nodeName.toLowerCase() === "input"
//         ? el
//         : el.getElementsByTagName("input")[0];
//     // 改变值过后，需要主动触发input时间，组件绑定的值才会改变
//     let triggerBySelf = false;
//     if (input) {
//       input.addEventListener("blur", () => {
//         if (triggerBySelf) {
//           triggerBySelf = false;
//           return;
//         }
//         let val = input.value;
//         // 验证数字
//         if (val === "") {
//           input.value = "";
//           return;
//         }

//         if (bind.expression) {
//           try {
//             const option = bind.value;
//             if (typeof option === "object") {
//               console.log(option);
//               const { decimals } = option;
//               console.log(number_format(val, decimals));
//               // val = number_format(val, decimals);
//               // 改变值过后，需要主动触发input时间，组件绑定的值才会改变
//               const ev = new Event("blur", { bubbles: true });
//               input.value = val;
//               triggerBySelf = true;
//               input.dispatchEvent(ev);
//             }
//           } catch (err) {
//             input.value = val;
//             console.error(err);
//           }
//         }
//       });
//     }
//   },
// };
/**
 * @example
 * v-number-format="{ limit: 1,min:1 }" 只允许输入一位小数
 * @param limit 小数位数
 */
export default {
  bind: (el, bind) => {
    const input =
      el.nodeName.toLowerCase() === "input"
        ? el
        : el.getElementsByTagName("input")[0];
    // 改变值过后，需要主动触发input时间，组件绑定的值才会改变
    let triggerBySelf = false;
    if (input) {
      input.addEventListener("blur", () => {
        if (triggerBySelf) {
          triggerBySelf = false;
          return;
        }

        let val = input.value;
        val = (val + "").replace(/[^0-9.]/g, "");
        console.log(val);
        // 验证数字
        if (val === "") {
          input.value = "";
          return;
        }
        const valArr = val.split(".");

        if (bind.expression) {
          try {
            const option = bind.value;
            if (typeof option === "object") {
              const { limit, min } = option;
              if (limit !== undefined) {
                if (valArr.length > 1) {
                  let pointLength = valArr[1].length;
                  pointLength = limit > pointLength ? pointLength : limit;
                  if (limit > 0) {
                    val =
                      +valArr[0] +
                      (valArr.length > 1
                        ? `.${valArr[1].substr(0, pointLength)}`
                        : "");
                  } else {
                    val = +valArr[0] + "";
                  }
                } else {
                  val = +val + "";
                }
              }
              if (typeof min === "number" && +val < +min) {
                val = min + "";
              }
              // 改变值过后，需要主动触发input时间，组件绑定的值才会改变
              const ev = new Event("input", { bubbles: true });
              input.value = val;
              triggerBySelf = true;
              input.dispatchEvent(ev);
            }
          } catch (err) {
            input.value = val;
            console.error(err);
          }
        }
      });
    }
  },
};
