const InnerLayout = () =>
  import(/* webpackChunkName:'innerLayout' */ "@/layouts/inner-layout");
const Option = () =>
  import(/* webpackChunkName:'baseTable' */ "@/pages/awarded/option");
const Stock = () =>
  import(/* webpackChunkName:'complexTable' */ "@/pages/awarded/stock");

const route = {
  name: "Awarded",
  path: "/awarded",
  component: InnerLayout,
  meta: {
    title: "授予管理",
    icon: "icon-shouyuguanli",
  },
  children: [
    {
      name: "AwardedOption",
      path: "/awarded/option",
      component: Option,
      meta: {
        title: "期权授予",
        type: "option",
      },
    },
    {
      name: "AwardedStock",
      path: "/awarded/stock",
      component: Stock,
      meta: {
        title: "股票授予",
        type: "stock",
      },
    },
  ],
};

export default route;
