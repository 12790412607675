import api from "@/api";
// const api = require("@/api");
import router from "@/router";
// console.log(router);
import cookie from "js-cookie";
const state = {
  token: sessionStorage.getItem("token"),
  userInfo: sessionStorage.getItem("userInfo")
    ? JSON.parse(sessionStorage.getItem("userInfo"))
    : {},
  routeMap: [],
};
const mutations = {
  SET_TOKEN(state, token) {
    sessionStorage.setItem("token", token);
    state.token = token;
  },
  SET_USER_INFO(state, userInfo) {
    sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
    state.userInfo = userInfo;
  },
  SET_ROUTE_MAP(state, routeMap) {
    state.routeMap = routeMap;
  },
};
const actions = {
  // 登录获取token
  Login({ commit }, loginInfo) {
    const username = loginInfo.username.trim(),
      password = loginInfo.password,
      code = loginInfo.code,
      rememberPwd = loginInfo.rememberPwd;

    return new Promise((resolve, reject) => {
      api.account
        .publicLogin({
          username,
          password,
          code,
          rememberPwd,
        })
        .then((res) => {
          commit("SET_USER_INFO", res);
          const token = res.user_token;
          commit("SET_TOKEN", token);
          if (loginInfo.rememberPwd) localStorage.setItem("username", username);
          else localStorage.removeItem("username");
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      api.account
        .publicLogout()
        .then(() => {
          commit("SET_TOKEN", "");
          commit("SET_USER_INFO", {});
          sessionStorage.clear();
          cookie.remove("session_id");
          router.replace("/account/login");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
