/* 核心工具 */

//生成guid
export function guid() {
  let S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}

// 生成随机数
export function random(min, max) {
  const choice = max - min + 1;
  return Math.floor(min + Math.random() * choice);
}
var num = random(2, 10);

/**
 * 滚动,先慢后快，缓动的效果比easeIn动画明显
 * @param {HTMLDOM} element  要滚动的元素
 * @param {Number} target    目标位置
 * @param {Number} duration  滚动所用的总时间
 * @param {Function} callback  滚动完成之后的回调
 */
export function scroll(element, target, duration = 500, callback = () => {}) {
  const startTime = Date.now();
  const move = () => {
    const passTime = Date.now() - startTime;
    const currentPosition = element.scrollTop;
    const residueDistance = target - currentPosition;
    const step = residueDistance / 10;
    element.scrollTop = currentPosition + step;
    if (passTime < duration) {
      window.requestAnimationFrame(move);
    } else {
      element.scrollTop = target;
      callback();
    }
  };
  move();
}

/**
 * 动画，可改变多个属性
 * @param {HTMLDOM} element  要发生动画的元素
 * @param {Object} properties    要改变的元素属性
 * @param {Number} interval  每次运动的时间间隔
 * @param {Function} callback  动画完成之后的回调
 */
export function animate(
  element,
  properties,
  interval = 20,
  callback = () => {}
) {
  clearInterval(element.timer);
  element.timer = setInterval(() => {
    let flag = true;
    for (const property in properties) {
      const current = parseInt(window.getComputedStyle(element)[property]);
      const target = properties[property];
      let step = (target - current) / 10;
      step = step > 0 ? Math.ceil(step) : Math.floor(step);
      element.style[property] = current + step + "px";
      if (current != target) {
        flag = false;
      }
    }
    if (flag) {
      clearInterval(element.timer);
      callback();
    }
  }, interval);
}

// 用于需要在get请求中传递数组的情况
export function paramsSerializer(params = {}) {
  const paramArr = [];
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      value.forEach((item) =>
        paramArr.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
      );
    } else {
      paramArr.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  }
  return paramArr.join("&");
}

/**
 * 获取url中的查询字符串参数
 * @param {String} url  url字符串
 */
export function getURLParams(url) {
  const search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
}

// 深克隆
export function deepClone(source) {
  if (typeof source !== "object" || source === null) {
    return source;
  }
  const target = Array.isArray(source) ? [] : {};
  for (const [key, value] of Object.entries(source)) {
    target[key] = deepClone(value);
  }
  return target;
}

// 获取元素相对于浏览器窗口边缘的的距离
export function getOffset(elem) {
  function getLeft(o) {
    if (o == null) {
      return 0;
    } else {
      return (
        o.offsetLeft +
        getLeft(o.offsetParent) +
        (o.offsetParent ? o.offsetParent.clientLeft : 0)
      );
    }
  }

  function getTop(o) {
    if (o == null) {
      return 0;
    } else {
      return (
        o.offsetTop +
        getTop(o.offsetParent) +
        (o.offsetParent ? o.offsetParent.clientTop : 0)
      );
    }
  }
  return { left: getLeft(elem), top: getTop(elem) };
}

// 节流
export function throttle(fn, interval = 100) {
  let timer = null;
  return function () {
    const context = this;
    const args = arguments;
    if (!timer) {
      timer = setTimeout(() => {
        timer = null;
        fn.apply(context, args);
      }, interval);
    }
  };
}

// 防抖
export function debounce(fn, interval = 100) {
  let timer = null;
  return function () {
    const context = this;
    const args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, interval);
  };
}

// 判断数据类型
export const getType = (value) =>
  value ? value.constructor.name.toLowerCase() : value;

// 加载第三方脚本
export function loadScript(src, callback = (err, res) => {}) {
  const existScript = document.getElementById(src);
  if (existScript) {
    callback(null, existScript);
  } else {
    const script = document.createElement("script");
    script.src = src;
    script.id = src;
    document.body.appendChild(script);
    script.onload = function () {
      callback(null, script);
    };
    script.onerror = function () {
      callback(new Error(`“${src}”加载失败`), script);
    };
  }
}
//form表单格式转换
export function stringifyParms(params) {
  return Object.keys(params)
    .sort()
    .map(
      (key) =>
        key +
        "=" +
        (!params[key] && typeof params[key] === "object" ? "" : params[key])
    )
    .join("&");
}
export const number_format = function (
  number,
  decimals,
  dec_point,
  thousands_sep
) {
  /*
   * 参数说明：
   * number：要格式化的数字
   * decimals：保留几位小数
   * dec_point：小数点符号
   * thousands_sep：千分位符号
   * */
  number = (number + "").replace(/[^0-9+-Ee.]/g, "");
  var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
    sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
    dec = typeof dec_point === "undefined" ? "." : dec_point,
    s = "",
    toFixedFix = function (n, prec) {
      var k = Math.pow(10, prec);
      return "" + parseInt(n * k) / k;
    };
  s = (prec ? toFixedFix(n, prec) : "" + parseInt(n)).split(".");

  var re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, "$1" + sep + "$2");
  }

  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return s.join(dec);
};
// 判断数组
export function isArrayFn(value) {
  if (typeof Array.isArray === "function") {
    return Array.isArray(value);
  } else {
    return Object.prototype.toString.call(value) === "[object Array]";
  }
}

//条件说明转换
export const conditionShow = function (arr) {
  const newArr = arr.map(
    (item, index) =>
      `批次${index + 1},执行比例${item.ratio}%,执行间隔${item.interval}个月`
  );
  return newArr;
};

//选项添加全部
export const addAllSelect = function (arr = []) {
  arr.unshift({ id: "", name: "全部" });
  return arr;
};

export const toDecimal2 = function (x) {
  var f = parseFloat(x);
  if (isNaN(f)) {
    return false;
  }
  f = Math.round(x * 100) / 100;
  var s = f.toString();
  var rs = s.indexOf(".");
  if (rs < 0) {
    rs = s.length;
    s += ".";
  }
  while (s.length <= rs + 2) {
    s += "0";
  }
  return s;
};
export const fomatFloat = function (src, pos) {
  return Math.round(src * Math.pow(10, pos)) / Math.pow(10, pos);
};

export const formatDecimal = (val, decimal) => {
  val = (val + "").replace(/[^0-9.]/g, "");
  let index = val.indexOf(".");
  if (index !== -1) {
    val = val.substring(0, decimal + index + 1);
  } else {
    val = val.substring(0);
  }
  return parseFloat(val).toFixed(decimal);
};

// 判断浏览器是不是ie
export const isIE = () => {
  const ua = window.navigator.userAgent;
  const isIE = ua.indexOf("MSIE") > -1;
  const isEdge = ua.indexOf("Edge") > -1;
  const isIE11 = ua.indexOf("Trident") > -1 && ua.indexOf("rv:11.0") > -1;
  return isIE || isEdge || isIE11;
};
// 香港手机号码正则验证
export const isHKMobile = (value) => {
  return /^(?:\+?852\-?)?[569]\d{7}$/.test(value);
};
// 大陆手机号码验证
export const isMobile = (value) => {
  return /^1[3456789]\d{9}$/.test(value);
};
