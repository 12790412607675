import Vue from "vue";

import copy from "./copy";
import dragDialog from "./dragDialog";
import permission from "./permission";
import numberFormat from "./numberFormat";

Vue.directive("copy", copy);
Vue.directive("dragDialog", dragDialog);
Vue.directive("permission", permission);
Vue.directive("numberFormat", numberFormat);
